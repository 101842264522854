import store from "@/store/index.js";
import { EventBus } from "@/event-bus";
import headerList from "@/components/common/header-list.vue";
import paginationMixin from "@/Mixins/paginationMixin";
import { _ } from "vue-underscore";
import assignModal from "../../manage/lead-assign.vue";
import feedbackModal from "../../manage/lead-feedback.vue";
import phoneCallModal from "../phoneCall-modal.vue";
import SmsModal from "../SMS-modal.vue";
import emailModal from "../email-modal.vue";
import leadCommentFeedbackModal from "../leadFeedbackComments-modal.vue";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import leadSearch from "../../../sales/list/saleRefSearch.vue";
export default {
  data: () => ({
    lead: {
      lead_id: "",
      lead_number: "",
      rate_request: "",
      isSelected: false,
    },
    showPhoneCall: false,
    selectedClient: {},
    showSmsCall: false,
    showEmails: false,
    showleadCommentFeedBack: false,
    lead_feedback_comment: [],
    comments: [],
    newComment: "",
    quotations: [],
    commentList: [],
    showLoader: false,
    searchText: "",
    modalOpen: false,
    selectedLead: null,
    selectedRating: null,
    selectedLeadNumber: "",
    selectedLeadSelfLeadAttribute: false,
    selectedLeadId: [],
    selectedLeadRating: "",
    currentComponent: null,
    newComment: "",
    showButton: false,
    selectedRows: [],
    selectedLeadIds: [],
    teamList: [],
    phoneButton: true,
    smsButton: true,
    emailButton: true,
    selectedTeam: "",
    selectedAgent: "",
    totalLeadsActive: 0,
    totalLeads: 0,
    totalLeadsSold: 0,
    totalLeadNoPqSent: 0,
    totalLeadFeedback: 0,
    totalLeadAskExpert: 0,
    totalLeadQuoted: 0,
    totalLeadTaken: 0,
    totalLeadUnquoted: 0,
    leadData: {},
    otherLeadData: {},
    queueOrder: [
      "Unassigned",
      "Unquoted",
      "Feedback",
      "Approval_Pending",
      "Referral_Approval_Pending",
      "Transfer_Approval_Pending",
      "Contact_Deletion_Request",
      "No_PQ_Sent",
      "Ask_Expert",
      "Active",
      "Taken",
      "Recently_Sold",
      "Quoted",
      "Bonus",
      "Duplicate",
      "Follow_Up_Done",
      "Wrong_Contact_Info",
      "Client_Not_Ready",
      "Closed",
      "Expired",
      "Sold",
      "Transferred",
    ],
    expandQueue: {
      Unassigned: true,
      Unquoted: true,
      Feedback: true,
      Approval_Pending: false,
      Referral_Approval_Pending: false,
      Transfer_Approval_Pending: false,
      Contact_Deletion_Request: false,
      No_PQ_Sent: true,
      Ask_Expert: false,
      Active: true,
      Taken: true,
      Recently_Sold: false,
      Quoted: false,
      Bonus: true,
      Duplicate: false,
      Wrong_Contact_Info: false,
      Follow_Up_Done: false,
      Client_Not_Ready: false,
      Closed: false,
      Expired: false,
      Sold: false,
      Transferred: false,
    },
    selectedReason: {
      queue: "",
      reason: "",
    },
    all_leads: localStorage.getItem("all_leads") === "true" || false,
    lastQueryString: "",
    callQueuePresence:
      parseInt(localStorage.getItem("call_queue_presence")) || 0,
    leadDateFrom: "",
    leadDateTo: "",
    clientLastname: "",
    clientFirstname: "",
    reply: [],
    companies: [],
    ClearSearch: false,
    leadSearch: false,
    selectedSearchInput: {
      agent: true,
      lead_id: true,
      client_name: true,
      client_email: true,
      client_phone: true,
      from: true,
      to: true,
    },
  }),
  mixins: [paginationMixin],
  components: {
    "header-list": headerList,
    "lead-assign": assignModal,
    "lead-feedback": feedbackModal,
    "phoneCall-modal": phoneCallModal,
    "SMS-modal": SmsModal,
    "email-modal": emailModal,
    "date-picker": DatePicker,
    "lead-CommentFeedBack": leadCommentFeedbackModal,
    "lead-search": leadSearch,
  },
  watch: {
    all_leads(newValue, oldVal) {
      localStorage.setItem("all_leads", newValue);
      this.getAll();
    },
    leadDateFrom(newVal) {
      if (!newVal) {
        this.leadDateTo = "";
      }
    },
  },
  computed: {
    user() {
      return this.$store.state.userDetail;
    },
    selectedLeadList() {
      let leads = [];
      Object.values(this.leadData).forEach((queue) => {
        queue.leads.forEach((lead) => {
          if (lead.isSelected == true) {
            leads.push(lead);
          }
        });
      });
      return leads;
    },
    isFeedbackPermission() {
      const permission = this.hasFeedbackPermission();
      return permission;
    },

    isActionButtonPermission() {
      return this.isCreateActionPermission || this.isFeedbackPermission;
    },
    isCreateActionPermission() {
      const permission = this.hasCreateActionPermission();
      return permission;
    },

    isCreateLeadPermission() {
      const permission = this.hasCreateLeadPermission();
      return permission;
    },
    isToggleSwitchPermission() {
      const permission = this.hasToggleSwitchPermission();
      return permission;
    },
    isActionHidePermission() {
      const permission = this.hasActionHidePermission();
      return permission;
    },

    isUnassignedInfoShowLeadPermission() {
      const permission = this.hasUnassignedInfoShowLeadPermission();
      return permission;
    },
  },
  methods: {
    TransferredCompany(company, id = 0) {
      let _vm = this;
      let ids = [];
      if (id > 0) {
        ids = [id];
      } else {
        Object.values(this.leadData).forEach((queue) => {
          queue.leads.forEach((lead) => {
            if (lead.isSelected === true) {
              ids.push(lead.id);
            }
          });
        });
      }

      this.selectedLeadIds = ids;
      this.$dialog
        .confirm(`Are you sure you want to transfer this Company ${company.name}?`)
        .then(function () {
          store.state.isLoaderShow = true;
          _vm.axios
            .post(`/transfer-leads/`, {
              leadIds: _vm.selectedLeadIds,
              company: company.slug,
            })
            .then(function () {
              store.state.isLoaderShow = false;
              _vm.getAll();
            })
            .catch(function (error) {
              store.state.isLoaderShow = false;
            });
        })
        .catch(function () {
          console.log("Transfer canceled");
        });
    },
    initializeCompanies() {
      const companiesString = process.env.VUE_APP_TRANSFERS_TO_COMPANIES;
      if (companiesString) {
        this.companies = companiesString.split(',').map(slug => {
          const name = slug.replace(/_/g, ' ').split(' ')
            .map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
          return {
            name: name,
            slug: slug
          };
        });
      }
    },
    refreshLeadList() {
      let _vm = this;
      _vm.getAll();
    },
    updateQueuePresence() {
      const newValue = this.callQueuePresence === 1 ? 0 : 1;
      this.axios
        .get("/update-queue-presence", {
          params: {
            presence: newValue,
          },
        })
        .then((response) => {
          this.callQueuePresence = newValue;
          localStorage.setItem("call_queue_presence", newValue);
        })
        .catch((error) => {
          console.error(error);
        });
    },
    // formatDateTime(timestamp) {
    //   const date = new Date(timestamp);
    //   const months = [
    //     "Jan",
    //     "Feb",
    //     "Mar",
    //     "Apr",
    //     "May",
    //     "Jun",
    //     "Jul",
    //     "Aug",
    //     "Sep",
    //     "Oct",
    //     "Nov",
    //     "Dec",
    //   ];

    //   const month = months[date.getMonth()];
    //   const day = date.getDate().toString().padStart(2, "0");
    //   const year = date.getFullYear().toString().slice(2);
    //   const time = timestamp.slice(11, 16);

    //   return `${month}/${day}/${year} ${time}`;
    // },
    formatQueueName(key) {
      switch (key) {
        case "Unassigned":
          return "Unassigned";
        case "No_PQ_Sent":
          return "No Quote Sent";
        case "Active":
          return "Active";
        case "Recently_Sold":
          return "Recently Sold";
        case "Feedback":
          return "Feedback Requested";
        case "Approval_Pending":
          return "Pending Approvals";
        case "Referral_Approval_Pending":
          return "Referral Pending Approvals";
        case "Transfer_Approval_Pending":
          return "Transfer Pending Approvals";
        case "Contact_Deletion_Request":
          return "Contact Deletion Requests";
        case "Ask_Expert":
          return "Quote Requested";
        case "Unquoted":
          return "Expert Unassigned";
        case "Taken":
          return "Expert Active";
        case "Quoted":
          return "Expert Quoted";
        case "Bonus":
          return "Bonus";
        case "Duplicate":
          return "Duplicate";
        case "Wrong_Contact_Info":
          return "Wrong Contact Info";
        case "Follow_Up_Done":
          return "Follow Up Done";
        case "Client_Not_Ready":
          return "Client Not Ready";
        case "Closed":
          return "Closed";
        case "Expired":
          return "Expired";
        case "Sold":
          return "Sold";
        case "Transferred":
          return "Transferred";
      }
    },
    toggleTable(queueIndex) {
      this.expandQueue[queueIndex] = !this.expandQueue[queueIndex];
    },
    hasActionHidePermission() {
      const slugsList = this.$store.state.slugsList;
      const desiredData = ["leads-assign", "leads-feedback"];

      let hasPermission = desiredData.some((item) => slugsList.includes(item));
      return hasPermission;
    },
    hasToggleSwitchPermission() {
      const slugsList = this.$store.state.slugsList;
      const desiredData = [
        "leads-unassigned-view-all-records",
        "leads-no-pq-sent-view-all-records",
        "leads-active-view-all-records",
        "leads-sold-view-all-records",
        "leads-feedback-view-all-records",
        "leads-ask-expert-view-all-records",
        "leads-unquoted-view-all-records",
        "leads-taken-view-all-records",
        "leads-quoted-view-all-records",
        "other-leads-transferred-view",
      ];

      let hasPermission = desiredData.some((item) => slugsList.includes(item));
      return hasPermission;
    },

    hasCreateLeadPermission() {
      const slugsList = this.$store.state.slugsList;
      const desiredData = "leads-create-lead";
      let hasPermission = slugsList.includes(desiredData);
      return hasPermission;
    },
    hasFeedbackPermission() {
      const slugsList = this.$store.state.slugsList;
      const desiredData = "leads-feedback";
      let hasPermission = slugsList.includes(desiredData);
      return hasPermission;
    },

    hasCreateActionPermission() {
      const slugsList = this.$store.state.slugsList;
      const desiredData = "leads-assign";
      let hasPermission = slugsList.includes(desiredData);
      return hasPermission;
    },

    hasUnassignedInfoShowLeadPermission() {
      const slugsList = this.$store.state.slugsList;
      const desiredData = "leads-unassigned-info-show";
      let hasPermission = slugsList.includes(desiredData);
      return hasPermission;
    },

    clearLocalStorageOnLogout() {
      localStorage.removeItem("allLeads");
    },
    getTeamDetail() {
      const _vm = this;
      this.axios
        .get("/team-details", _vm.teamList)
        .then(function (response) {
          _vm.teamList = response.data.data;
        })
        .catch(function () { });
    },
    assignUser(id = 0) {
      let ids = [];
      if (id > 0) {
        ids = [id];
      } else {
        Object.values(this.leadData).forEach((queue) => {
          queue.leads.forEach((lead) => {
            if (lead.isSelected == true) {
              ids.push(lead.id);
            }
          });
        });
      }
      this.selectedLeadIds = ids;
      this.currentComponent = "lead-assign";
    },
    updateAssignment() {
      this.currentComponent = null;
      this.getAll(false);
    },
    phoneCall(lead, leadNumber) {
      let _vm = this;
      let clientId = lead.client_id;
      _vm.getDetail(clientId);
      _vm.selectedLeadNumber = leadNumber;
      _vm.selectedLeadId = [lead.id];
      _vm.selectedLead = lead;
      _vm.showPhoneCall = true;
    },
    sms(lead, leadNumber) {
      let _vm = this;
      let clientId = lead.client_id;
      _vm.getDetail(clientId);
      _vm.selectedLeadNumber = leadNumber;
      _vm.selectedLeadId = [lead.id];
      _vm.selectedLead = lead;
      _vm.showSmsCall = true;
    },

    email(lead, leadNumber) {
      let _vm = this;
      let clientId = lead.client_id;
      _vm.getDetail(clientId);
      _vm.selectedLeadNumber = leadNumber;
      _vm.selectedLeadId = [lead.id];
      _vm.selectedLead = lead;
      _vm.showEmails = true;
    },
    leadCommentFeedbackInfo(lead) {
      let _vm = this;
      _vm.selectedLead = lead;
      _vm.showleadCommentFeedBack = true;
    },
    updateFeedback() {
      this.currentComponent = "";
      this.getAll(false);
    },

    handleSearch(selectedData) {
      let _vm = this;
      _vm.getAll(selectedData);
      _vm.leadSearch = false;
      _vm.ClearSearch = true;
    },
    getQueryString(selectedData) {
      let queryString = "?all_leads=" + this.all_leads;
      queryString += selectedData.agent_id
        ? "&agent=" + selectedData.agent_id
        : "";
      queryString += selectedData.Lead ? "&lead_id=" + selectedData.Lead : "";
      queryString += selectedData.Client_name
        ? "&client_name=" + selectedData.Client_name
        : "";
      queryString += selectedData.client_email
        ? "&client_email=" + selectedData.client_email
        : "";
      queryString += selectedData.client_phone
        ? "&client_phone=" + selectedData.client_phone
        : "";
      queryString += selectedData.saleDateFrom
        ? "&from=" + selectedData.saleDateFrom
        : "";
      queryString += selectedData.saleDateTo
        ? "&to=" + selectedData.saleDateTo
        : "";
      return queryString;
    },
    getAll(selectedData = {}) {
      let _vm = this;
      store.state.isLoaderShow = true;
      if (Object.keys(selectedData).length === 0 && this.lastQueryString) {
        selectedData = this.lastQueryString;
      } else {
        this.lastQueryString = selectedData;
      }
      let queryString = this.getQueryString(selectedData);
      this.axios
        .get("/leads-by-groups" + queryString)
        .then(function (response) {
          store.state.isLoaderShow = false;
          try {
            let data = JSON.parse(atob(response.data.data));

            let reorderedData = {};
            let otherData = {};
            _vm.queueOrder.forEach((key) => {
              if (data.active_leads.hasOwnProperty(key)) {
                data.active_leads[key].leads.forEach((lead) => {
                  lead["isSelected"] = false;
                });
                reorderedData[key] = data.active_leads[key];
              }
            });
            _vm.leadData = reorderedData;

            _vm.queueOrder.forEach((key) => {
              if (data.other_leads.hasOwnProperty(key)) {
                otherData[key] = data.other_leads[key];
              }
            });
            _vm.otherLeadData = otherData;
          } catch (error) { }
        })
        .catch(function (error) { });
    },
    setAssign(event, leadId) {
      let _vm = this;
      this.$nextTick(() => {
        this.axios
          .post(
            `/assign-user?lead_id=${leadId}` + "&user_id=" + event.target.value
          )
          .then(function () {
            _vm.getAll();
          })
          .catch(function (error) { });
      });
    },
    getDetail(clientId) {
      let _vm = this;
      this.axios
        .get("/client-by-lead/" + clientId)
        .then(function (response) {
          _vm.selectedClient = JSON.parse(atob(response.data.data));
        })
        .catch(function (error) {
          // Handle error
        });
    },

    handleCheck(e, id) {
      if (e.target.id === "selectAllCheck") {
        Object.values(this.leadData).forEach((queue) => {
          queue.leads.forEach((lead) => {
            lead.isSelected = e.target.checked;
          });
        });
      } else {
        Object.values(this.leadData).forEach((queue) => {
          queue.leads.forEach((lead) => {
            if (lead.id == id) {
              lead.isSelected = e.target.checked;
            }
          });
        });
      }
    },

    unassignLead(status) {
      this.$router.push("leads/" + status);
    },
    openNewTab(queue) {
      window.open("leads/" + queue, "_blank");
    },
    // for popup search
    openModal() {
      this.leadSearch = true;
    },
    clearAll() {
      let _vm = this;
      _vm.ClearSearch = false;
      _vm.lastQueryString = "";
      store.state.isLoaderShow = true;
      _vm.getAll();
    },
    getLead(id) {
      return this.axios
        .get("/leads-unassigned/" + id)
        .then((response) => {
          return JSON.parse(atob(response.data.data));
        })
        .catch((error) => {
          console.error("Error fetching lead:", error);
          return null;
        });
    },
    handleNewLeadGenerated(notification) {
      let that = this;
      if (localStorage.getItem("all_leads") == "true") {
        that
          .getLead(notification.data.lead_id)
          .then((leadData) => {
            if (!that.leadData["Unassigned"]) {
              that.leadData["Unassigned"] = { leads: [], total_leads: 0 };
            }
            leadData.isSelected = false;
            that.leadData["Unassigned"].leads.unshift(leadData);
            that.leadData["Unassigned"].total_leads++;
            that.forceUpdate();
          })
          .catch((error) => {
            console.error("Error fetching lead data:", error);
          });
      }
    },
    forceUpdate() {
      this.$forceUpdate();
    },
    handleNewLeadAssigned(notification) {
      let that = this;
      if (localStorage.getItem("all_leads") == "true") {
        this.leadData["Unassigned"].leads = this.leadData[
          "Unassigned"
        ].leads.filter((l) => l.id != notification.data.lead_id);
        that.leadData["Unassigned"].total_leads--;
      }
    },
  },
  created() {
    EventBus.$on("NewLeadGenerated", (notification) => {
      this.handleNewLeadGenerated(notification);
    });

    EventBus.$on("NewLeadAssigned", (notification) => {
      this.handleNewLeadAssigned(notification);
    });
  },
  beforeDestroy() {
    EventBus.$off("NewLeadGenerated");
    EventBus.$off("NewLeadAssigned");
  },
  mounted() {
    this.getAll();
    this.initializeCompanies();
    this.getTeamDetail();
    this.callQueuePresence =
      parseInt(localStorage.getItem("call_queue_presence")) || 0;
  },
};
